/* ==========================================================================
   Helpers
   ========================================================================== */

/* Alignment
   ========================================================================== */

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

/* Visibility
   ========================================================================== */

.hidden {
    display: none !important;
}

.invisible {
    /* Used to animate fade-ins */
    visibility: hidden;
    opacity: 0;
}

@media (--to-sm) {
    .hidden--sm {
        display: none !important;
    }
}

@media (--to-md) {
    .hidden--md {
        display: none !important;
    }
}

@media (--sm) {
    .show--sm {
        display: none;
    }
}

/* Split items
   ========================================================================== */

/*
 * Will align items to far left and right within a container
 */

.split {
    display: flex;
}

.split__left {
    text-align: left;
}

.split__right {
    text-align: right;
    margin-left: auto;
}

/**
 * Add this attribute to avoid FOUC on Vue components
   https://vuejs.org/v2/api/#v-cloak
 */
[v-cloak] {
    display: none;
}

/* Margins
   ========================================================================== */

.m-t-24 {
    margin-top: 24px;
}

.m-b-24 {
    margin-bottom: 24px;
}