/* ==========================================================================
   Subscriber Import
   ========================================================================== */

input[type='file'] {
    color: rgba(255, 255, 255, 0.9);
}

.importPreview {
    font-size: 14px;
    max-height: 400px; /* TODO: Work out height based on client height */
    overflow-y: auto;
}

.importPreview__form p {
    font-size: 13px;
}

.importPreview__header {
    padding: 0.4em;
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    vertical-align: text-top;
    background-color: var(--theme-dark-palette-darker);
    min-width: 180px;

    & .text {
        display: block;
        width: 100%;
        margin-top: 5px;
        font-size: 12px;
    }

    & .field {
        margin: 0;
    }
}

.importPreview__table {
    width: 100%; /* stops edge-case horizontal scrollbars appearing */
    margin: 20px 0;
    position: relative;

    & tbody td {
        white-space: nowrap;
        padding: 10px;
        font-size: 12px;
    }
}

.importPreview__actionbuttons {
    margin-top: 2em;
}

.import-progress {
    margin: 20px 0;
}

.import-progress__legends {
    text-align: center;
    font-size: 11px;

    & .-legend {
        margin-right: 2em;
    }

    & .-square {
        display: inline-block;
        height: 20px;
        width: 20px;
        margin-right: 8px;
        border-radius: 2px;
        vertical-align: middle;
    }
}

.import-progress__bar {
    position: relative;
    margin: 20px 0;
    padding: 5px;
    height: 40px;
    border-radius: 3px;
    background: var(--theme-dark-palette-darker);
    color: var(--theme-light-text);

    & > svg {
        width: 100%;

        & text {
            font-size: 13px;
            color: rgba(0, 0, 0, 0.8);
        }
    }

    & rect {
        height: 100%;
        position: relative;
        overflow: hidden;
    }
}

.import-progress__text {
    position: absolute;
    top: 0;
    left: 15px;
    height: 40px;
    line-height: 41px;
    font-size: 13px;
    color: #fff;
}
