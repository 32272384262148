/* ==========================================================================
   Typography
   ========================================================================== */

@font-face {
  font-family: "Figtree";
  src: url('https://login.taguchi.com.au/fonts/Figtree[wght].woff2') format('woff2-variations');
  font-weight: 100 900;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Figtree";
  src: url('https://login.taguchi.com.au/fonts/Figtree-Italic[wght].woff2') format('woff2-variations');
  font-weight: 100 900;
  font-style: italic;
  font-display: fallback;
}

body {
    font-size: 100%;
    font-family: 'Figtree', sans-serif;
    font-weight: 300;
}

/**
 * Headers
 *
 * Allows classes to resize any header tag.
 * e.g. <h2 class="alpha">Big Text</h2>
 */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}

h1 {
    font-size: 24px;
}
h2 {
    font-size: 21px;
}
h3 {
    font-size: 18px;
}
h4 {
    font-size: 16px;
}
h5 {
    font-size: 13px;
}
h6 {
    font-size: 11px;
}

/**
 * Common font settings
 */

b,
strong {
    font-weight: 700;
}

a {
    color: var(--link-default);
}

a:hover {
    color: var(--link-hover);
}

a:active {
    color: var(--link-active);
}

pre.wrap {
    white-space: pre-wrap;
}

button,
select,
textarea,
input {
    font-weight: 300;
}

.expression {
    font-family: monospace;
    font-size: 0.9rem;
}

kbd {
    display: flex;
    min-width: 20px;
    padding: 0 4px;
    height: 20px;
    background-color: var(--colour-slate-50);
    color: var(--text-dark);
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    font: 500 14px 'Figtree', sans-serif;
}
