/* ==========================================================================
   Modals
   ========================================================================== */

/* Force body to not scroll. Applied to <body> */
.modal--active {
    @media (--sm) {
        overflow: hidden;
    }
}

.modal {
    --modal-header-height: 64px;

    position: fixed;
    z-index: 1000;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    background-color: #fff;
    color: var(--text-dark);
    transition: all 0.3s;
    transform: scale(0.7);

    .modal--open & {
        visibility: visible;
        transform: scale(1);
        opacity: 1;
    }

    &.-complex {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: var(--modal-header-height) 1fr;

        & .modal__header {
            grid-row: 1;
        }

        & .modal__body {
            grid-row: 2;
            align-items: stretch;
            justify-content: flex-start;
        }
    }

    &.-light {
        background-color: #fff;
    }

    &.-dark {
        background-color: var(--theme-dark-palette);
        color: var(--theme-light-text);
    }
}

.modal__header {
    display: flex;
    align-items: stretch;
    width: 100%;
    height: var(--modal-header-height);
    z-index: 900;
    padding: 0;
    font-weight: 500;

    .modal.-dark & {
        background-color: var(--theme-dark-palette-dark);
        color: var(--theme-light-text);
    }

    .modal.-light & {
        background-color: var(--colour-slate-50);
        color: var(--text-dark);
        border-bottom: 1px solid var(--border-light);
    }

    .modal.-noheader & {
        display: none;
    }
}

.modal__body {
    /* Simple layout by default */
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 20px;
    overflow-y: auto;

    & form {
        margin-bottom: 20px;
    }

    .modal.-noheader & {
        height: 100%;
        margin-top: 0;
    }

    #edit-activity & {
        padding: 0;
    }

    .stats__header h3 {
        font-size: 24px;
        margin-top: 64px;
    }
}

.modal__body::-webkit-scrollbar {
    width: 8px;
}

.modal__body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.modal__body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: var(--text-dark-light);
}

/* Modal Header
   ========================================================================== */

.modal-header {
    display: flex;
    align-items: center;
    width: 100%;
}

.modal-header__icon {
    margin-right: 12px;
    flex: 0 0 44px;
}

.modal-header__details {
    margin: 0;
    padding: 0;
}

.modal-header__suptitle {
    font-size: 12px;
    color: var(--text-dark-light);
    line-height: 1;
    padding-bottom: 2px;
}

.modal-header__title {
    flex: 1;
    color: var(--text-dark);
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    word-break: break-all;
    hyphens: auto;
}

.modal-header__id {
    font-size: 12px;
    color: var(--text-dark-light);
}

.modal-header__actions {
    margin-left: auto;
    display: flex;
    padding-right: 4px;

    & .btn-icon {
        cursor: pointer;
    }

    .modal.-dark & svg {
        fill: var(--theme-light-text);
    }

    .modal.-light & svg {
        fill: var(--text-dark);
    }
}

/* Modal Inner Contents
   ========================================================================== */

.modal__inner {
    width: 100%;
    margin: auto; /* Stops flex cutoff */
    padding-bottom: 20px;

    @media (--sm) {
        width: 420px;
    }

    @media (--xl) {
        .modal.-complex & {
            width: 90%;
        }
    }

    .modal.-complex & {
        margin-top: 0;
        width: 100%;
        transition: width 1s;

        @media (--xl) {
            width: 90%;
        }

        @media (--xxl) {
            width: 75%;
        }
    }

    .modal.-column & {
        margin-top: 80px;
        width: 680px;
        transition: width 1s;
        position: relative;

        @media (--sm-only) {
            width: 90%;
        }
    }

    #edit-activity & {
        width: 100%;
    }
}

.modal__category {
    padding: 0 16px;
    margin: 0 16px 0 0;
    border-right: 1px solid;

    & h2 {
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0;
        font-size: 16px;
        white-space: nowrap;
        font-weight: 500;
    }

    .modal.-dark & {
        border-color: var(--theme-dark-palette-light);

        & h2 {
            color: var(--theme-light-text);
        }
    }

    .modal.-light & {
        border-color: var(--border-light);

        & h2 {
            color: var(--text-dark);
        }
    }
}

.modal__close {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 64px;
    margin-left: auto;
    border-left: 1px solid;

    & svg {
        margin: 0 !important;
        fill: var(--text-dark);

        .modal.-dark & {
            fill: var(--theme-light-text);
        }
    }

    .modal.-dark & {
        border-color: var(--theme-dark-palette-light);
    }

    .modal.-light & {
        border-color: var(--border-light);
    }

    .modal-header__actions + & {
        margin-left: 0;
    }
}

.modal__title {
    color: var(--text-dark);

    .modal.-dark & {
        color: var(--theme-light-text);
    }
}

.modal__frame {
    .modal.-complex & {
        border: 0;
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        height: 100%;
    }

    /* Hook for the older non-workflow interface */
    &.-noworkflow {
        position: absolute;
        left: 0;
        top: 64px;
        height: calc(100% - 64px) !important;
    }
}

.modal__grid {
    width: 100%;
    display: grid;
    gap: 20px;

    &.-seconds {
        @media (--md) {
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: minmax(100px, auto);
        }

        &.margin {
            margin-bottom: 20px;
        }
    }

    &.-thirds {
        @media (--md) {
            grid-template-columns: repeat(3, 1fr);
            grid-auto-rows: minmax(100px, auto);
        }
    }

    &.-editor {
        @media (--md) {
            grid-template-columns: 1fr 2fr;
        }
    }

    &.-fourths {
        @media (--md) {
            grid-template-columns: repeat(4, 1fr);
            grid-auto-rows: minmax(400px, auto);
        }
    }
}

.modal__griditem {
    &.box {
        margin-bottom: 0;
    }
}

.modal__centered {
    width: 60%;
}
