/* ==========================================================================
   Lists
   ========================================================================== */

/* Default stacked list
   ========================================================================== */

.list {
    list-style: none;
    margin: 0;
    padding: 0;
}

/* TODO: Redo event title back as generic list */
.list__title {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.4em;
}

.list__detail {
    font-size: 11px;
    line-height: 1.4em;
}

.list__item {
    margin: 0;
    padding: 0;
    width: 100%;
}

/* Inline list
   ========================================================================== */

.list--inline {
    list-style: none;
    margin: 0;
    padding: 0;

    & > * {
        display: block;
        float: left;
        vertical-align: top;
        position: relative;
    }
}
