/* ==========================================================================
   Drag and Drop
   ========================================================================== */

.drag__inprogress {
    position: relative;

    & .drag__dropzone {
        display: block;
    }
}

.drag__dropzone {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    margin: 0 0 10px 10px;
    display: none;
    padding: 20px;
    z-index: 100;
    background: hsla(210, 10%, 0%, 0.75);
    border: 3px var(--theme-dark-palette-darker) dashed;
    color: #fff;
    text-align: center;

    & svg-icon {
        display: inline-block;
        margin-top: 30px;
    }

    & svg {
        width: 100px;
        height: 100px;
    }
}

.drag__instructions {
    margin-top: 20px;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.3em;

    & span {
        display: block;
        margin-top: 5px;
        font-size: 11px;
        line-height: 1.3em;
        font-weight: normal;
    }
}

/* https://css-tricks.com/snippets/css/shake-css-keyframe-animation/ */
.drag__reject {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;

    &::before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 10px;
        height: calc(100% - 10px);
        width: calc(100% - 10px);
        z-index: 10;
        content: 'Incorrect file type or upload not supported';
        padding: 175px 20px 0;
        background: rgba(221, 98, 102, 0.75) url(../img/icon-ban-white.svg) no-repeat 50% 50px;
        background-size: 100px;
        border: 3px var(--status-negative) dashed;
        text-align: center;
        color: #fff;
        font-weight: bold;
    }
}

/* TODO make this reusable */
@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
