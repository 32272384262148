/* ==========================================================================
   Buttons
   ========================================================================== */

/* FIXUP: Redo button classes to make them uniform to input heights */

:root {
    --btn-height: 40px;
    --btn-height-small: 32px;
    --btn-font-size: 14px;
    --btn-font-size-small: 12px;
    --btn-border-radius: 4px;

    --btn-default-colour: var(--colour-slate-600);
    --btn-default-colour-hover: var(--colour-slate-500);
    --btn-default-colour-active: var(--colour-slate-700);

    --btn-primary-colour: var(--colour-blue-400);
    --btn-primary-colour-hover: var(--colour-blue-300);
    --btn-primary-colour-active: var(--colour-blue-500);

    --btn-positive-colour: var(--colour-mint-400);
    --btn-positive-colour-hover: var(--colour-mint-300);
    --btn-positive-colour-active: var(--colour-mint-500);

    --btn-negative-colour: var(--colour-red-500);
    --btn-negative-colour-hover: var(--colour-red-400);
    --btn-negative-colour-active: var(--colour-red-700);

    --btn-neutral-colour: var(--colour-saffron-500);
    --btn-neutral-colour-hover: var(--colour-saffron-400);
    --btn-neutral-colour-active: var(--colour-saffron-700);
}

.btn {
    appearance: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    height: var(--btn-height);
    font-size: var(--btn-font-size);
    color: #fff;
    border-radius: var(--btn-border-radius);
    border: 0;
    cursor: pointer;
    line-height: 1;
    background-color: var(--btn-default-colour);
    outline: none;
    white-space: nowrap;
    user-select: none;
    transition: background .2s, border .2s, box-shadow .2s, color .2s;
    box-shadow: 0 1px 2px rgba(0 0 0 / 0.1);

    &:focus {
        box-shadow: 0 0 0 4px rgba(107 161 203   / .2);
    }

    &:focus,
    &:hover {
        background-color: var(--btn-default-colour-hover);
        color: #fff;
    }

    &:active {
        background-color: var(--btn-default-colour-active);
    }

    &[disabled],
    &:disabled,
    &.disabled {
        background-color: rgba(92, 102, 112, 0.3);
        color: #f6f6f6;
        cursor: not-allowed;
        opacity: 0.5;
    }

    &.-small {
        height: var(--btn-height-small);
        font-size: var(--btn-font-size-small);

        &.-wide {
            padding: 10px 20px 9px;
        }

        &.-loading:after {
            width: 16px;
            height: 16px;
        }
    }

    &.-primary {
        background-color: var(--btn-primary-colour);

        &:hover {
            background-color: var(--btn-primary-colour-hover);
        }

        &:active {
            background-color: var(--btn-primary-colour-active);
        }
    }

    &.-positive {
        background-color: var(--btn-positive-colour);

        &:hover {
            background-color: var(--btn-positive-colour-hover);
        }

        &:active {
            background-color: var(--btn-positive-colour-active);
        }
    }

    &.-neutral {
        background: var(--btn-neutral-colour);
        color: var(--colour-saffron-50);

        &:hover {
            background-color: var(--btn-neutral-colour-hover);
        }

        &:active {
            background-color: var(--btn-neutral-colour-active);
        }
    }

    &.-negative {
        background-color: var(--btn-negative-colour);

        &:hover {
            background-color: var(--btn-negative-colour-hover);
        }

        &:active {
            background-color: var(--btn-negative-colour-active);
        }
    }

    /* Displays button as a link. */
    &.-link {
        background: transparent;
        border-color: transparent;
        color: var(--text-dark);
        box-shadow: none;

        &:focus,
        &:hover,
        &:active,
        &.active {
            color: var(--theme-dark-text-active);
        }

        &.-light {
            color: var(--theme-light-text);

            &:focus,
            &:hover,
            &:active,
            &.active {
                color: var(--theme-light-text-active);
            }
        }

        &[disabled],
        &:disabled,
        &.disabled {
            background: transparent;
        }
    }

    &.-fullwidth {
        width: 100%;
        margin: 0;
    }

    &.-boxed {
        width: calc(100% - 20px);
        margin: 10px;
    }

    &.-wide {
        padding-left: 30px;
        padding-right: 30px;
    }

    &.-last {
        margin-right: 0;
    }

    &.-loading span {
        opacity: 0;
    }

    &.-loading:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        display: block;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 3px;
    }

    &.-loading:after {
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        width: 21px;
        height: 21px;
        display: inline-block;
        border-radius: 50%;
        border-style: solid;
        border-width: 0.18em;
        border-color: currentColor rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2);
        animation: load-spin 0.8s linear infinite;
    }

    &.-toolbar {
        height: 40px;
        border: 1px solid var(--colour-slate-200);
        background: #fff;
        color: var(--text-dark);
    }
}

@keyframes load-spin {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.2);
    }

    50% {
        transform: rotate(180deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Icon Buttons
   ========================================================================== */

/* TODO: Rework button icon code so that it just uses the standard button code above */

/**
 * Example:
 * <a href="#" class="btn--icon"><span>Foo</span></a>
 */

.btn--icon {
    display: inline-flex;
    box-sizing: border-box;
    color: #fff;
    height: 34px;
    width: 34px;
    margin: 3px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline-style: none;
    transition: background-color 0.3s ease-out;
    position: relative;
    border-radius: var(--btn-border-radius);

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }

    & span:not(.svg-icon__wrap) {
        display: none;
    }

    &.-active {
        background-color: var(--theme-light-palette);
    }

    &.-round {
        border-radius: 40px;
    }

    &.-light {
        &:hover {
            background-color: rgba(0, 0, 0, 0.3);
        }
    }

    &.-staticbg {
        background-color: transparent;
    }

    /* Small Icon Buttons */
    &.-small {
        font-size: 12px !important;
        height: 30px;
        width: 30px;
    }

    /* Tiny Icon Buttons */
    &.-tiny {
        height: 24px;
        width: 24px;

        & svg {
            height: 14px;
            width: 14px;
        }
    }

    &.-nomargin {
        margin: 0px;
    }

    &.-default {
        background-color: var(--btn-default-colour);

        &:hover {
            background-color: var(--btn-default-colour-hover);
        }

        &:active {
            background-color: var(--btn-default-colour-active);
        }
    }

    &.-positive {
        background-color: var(--btn-positive-colour);

        &:hover {
            background-color: var(--btn-positive-colour-hover);
        }

        &:active {
            background-color: var(--btn-positive-colour-active);
        }
    }

    &.-negative {
        background-color: var(--btn-negative-colour);

        &:hover {
            background-color: var(--btn-negative-colour-hover);
        }

        &:active {
            background-color: var(--btn-negative-colour-active);
        }
    }

    &.-inactive {
        background-color: var(--status-inactive);
        cursor: not-allowed;
    }

    &.-loading:before {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        content: '';
        display: block;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 4px;
    }

    &.-loading:after {
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        width: 21px;
        height: 21px;
        display: inline-block;
        border-radius: 50%;
        border-style: solid;
        border-width: 0.18em;
        border-color: var(--status-default) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2);
        animation: load-spin 0.8s linear infinite;
    }

    &.-inline {
        margin: 0 3px;
        height: auto;
        width: auto;

        & svg {
            margin: auto;
        }
    }
}

/* Button Groups
   ========================================================================== */

.btn-group {
    display: inline-flex;
    border-radius: var(--btn-border-radius);
    box-shadow: 0 1px 2px 0 rgba(0 0 0 / 0.1);

    & .btn--icon {
        border: var(--border-width) solid var(--border-color);
        border-right: 0;
        height: 40px;
        width: 40px;
        margin: 0;
        border-radius: 0;

        & svg {
            fill: var(--text-dark-light);
        }

        &:hover svg,
        &:active svg {
            fill: var(--text-dark);
        }

        &:first-child {
            border-radius: var(--btn-border-radius) 0 0 var(--btn-border-radius);
        }

        &:last-child {
            border: var(--border-width) solid var(--border-color);
            border-radius: 0 var(--btn-border-radius) var(--btn-border-radius) 0;
        }

        &.-active {
            background-color: transparent;

            & svg {
                fill: var(--form-active);
            }
        }
    }
}

.btn-link {
    cursor: pointer;
}

/* Responsive Button Row
   ========================================================================== */

.btn-row {
    @media (--md) {
        width: 100%;
        display: flex;
    }

    & .btn {
        width: 100%;
        margin-bottom: 10px;

        @media (--md) {
            width: auto;
            margin-right: 5px;
        }
    }
}

.btn-row__first,
.btn-row__last {
    width: 100%;

    @media (--md) {
        width: 30%;

        &.-large {
            width: 70%;
        }
    }
}

.btn-row__last {
    margin-left: auto;
    text-align: right;

    & .btn:last-child {
        margin-right: 0;
    }
}

/* Split Button
   ========================================================================== */

.btn-split {
    display: inline-flex;
    outline: 0;
    position: relative;
    cursor: pointer;

    &.-loading span {
        opacity: 0;
    }

    &.-loading:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        display: block;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 3px;
    }

    &.-loading:after {
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        width: 21px;
        height: 21px;
        display: inline-block;
        border-radius: 50%;
        border-style: solid;
        border-width: 0.18em;
        border-color: currentColor rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2);
        animation: load-spin 0.8s linear infinite;
    }

    &.-small {
        .btn-split__main {
            height: var(--btn-height-small);
            font-size: var(--btn-font-size-small);
        }

        .btn-split__disclosure {
            height: var(--btn-height-small);
        }
    }
}

.btn-split__main {
    border-radius: 3px 0 0 3px;
    margin-right: 0 !important;
}

.btn-split__disclosure {
    border-radius: 0 3px 3px 0;
    margin-left: 0 !important;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.2);
    position: relative;

    &::after {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: calc(50% - 3px);
        content: '';
        width: 0;
        height: 0;
        margin-top: -2px;
        pointer-events: none;
        border-top: 4px solid #fff;
        border-right: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 4px solid transparent;
        border-radius: 0;
    }
}
