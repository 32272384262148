/* ==========================================================================
   Variables
   ========================================================================== */

:root {
    /* Use the following as the base pixel size for em calculations */
    --em-base: 16px;

    /* Colours
   ========================================================================== */

    /*
    * Naming based on http://chir.ag/projects/name-that-color/
    * The colour variable names are intended to only be used within this page
    */
    --color-outer-space: #2d3237;
    --colour-danube: #6ba1cb;
    --colour-japonica: #dd6266;
    --colour-atlantis: #a1cb33;
    --colour-cascade: #95a5a6;
    --colour-mustard: #ffde52;
    --colour-porcelain: #ecf0f1;
    --colour-athensgrey: #ebeef2;
    --colour-deyork: #75c282;
    --colour-trout: #505560;
    --colour-havelockblue: #65aadb;
    --colour-regentgrey: #8f99a3;

    /* Grey / Slate */
    --colour-slate-50: #f5f6f8;
    --colour-slate-100: #e6e8ec;
    --colour-slate-200: #d0d5dc;
    --colour-slate-300: #b4bcc7;
    --colour-slate-400: #97a1b1;
    --colour-slate-500: #7a879b;
    --colour-slate-600: #617187;
    --colour-slate-700: #525f72;
    --colour-slate-800: #3e4756;
    --colour-slate-900: #2c333d;
    --colour-slate-950: #1a1e24;

    /* Red */
    --colour-red-50: #fff4f2;
    --colour-red-100: #ffe2dd;
    --colour-red-200: #ffc7c0;
    --colour-red-300: #ffa399;
    --colour-red-400: #fa7970;
    --colour-red-500: #f04b49;
    --colour-red-600: #c93c3c;
    --colour-red-700: #a93533;
    --colour-red-800: #7d2b28;
    --colour-red-900: #57211e;
    --colour-red-950: #301613;

    /* Yellow / Saffron */
    --colour-saffron-50: #fff6e3;
    --colour-saffron-100: #ffe5b3;
    --colour-saffron-200: #ffcc6d;
    --colour-saffron-300: #f5ae34;
    --colour-saffron-400: #d3942e;
    --colour-saffron-500: #b27c27;
    --colour-saffron-600: #956621;
    --colour-saffron-700: #7f561c;
    --colour-saffron-800: #604015;
    --colour-saffron-900: #452e0f;
    --colour-saffron-950: #281b09;

    /* Green / Mint */
    --colour-mint-50: #d9fef3;
    --colour-mint-100: #88fdd9;
    --colour-mint-200: #28f0b2;
    --colour-mint-300: #23d49d;
    --colour-mint-400: #1eb687;
    --colour-mint-500: #199871;
    --colour-mint-600: #157f5e;
    --colour-mint-700: #126b50;
    --colour-mint-800: #0d513c;
    --colour-mint-900: #0a3a2b;
    --colour-mint-950: #062219;

    /* Teal */
    --colour-teal-50: #e6faff;
    --colour-teal-100: #bbf1fe;
    --colour-teal-200: #76e3fd;
    --colour-teal-300: #28ccf2;
    --colour-teal-400: #23aecf;
    --colour-teal-500: #1d92ae;
    --colour-teal-600: #187a90;
    --colour-teal-700: #14677a;
    --colour-teal-800: #0f4d5c;
    --colour-teal-900: #0b3842;
    --colour-teal-950: #062127;

    /* Blue */
    --colour-blue-50: #f1f7ff;
    --colour-blue-100: #d9eaff;
    --colour-blue-200: #b8d7ff;
    --colour-blue-300: #8bbfff;
    --colour-blue-400: #52a3ff;
    --colour-blue-500: #0086ff;
    --colour-blue-600: #006bf7;
    --colour-blue-700: #005bd2;
    --colour-blue-800: #00459f;
    --colour-blue-900: #003273;
    --colour-blue-950: #001e45;

    /* Violet */
    --colour-violet-50: #fbf4ff;
    --colour-violet-100: #f6e1ff;
    --colour-violet-200: #edc7fe;
    --colour-violet-300: #e2a4fe;
    --colour-violet-400: #d57bfd;
    --colour-violet-500: #c54afc;
    --colour-violet-600: #ac27e8;
    --colour-violet-700: #9221c5;
    --colour-violet-800: #6f1995;
    --colour-violet-900: #50126c;
    --colour-violet-950: #300b41;

    /* Theme Colour - Redux
    ========================================================================== */

    --bg-light: #fff;
    --bg-light-tone: var(--colour-slate-50);

    --border-light: var(--colour-slate-100);
    --border-dark: var(--colour-slate-800);

    --text-dark: var(--colour-slate-700);
    --text-dark-light: var(--colour-slate-400);

    /* Theme Colour
    ========================================================================== */
    --body-background-color: var(--colour-athensgrey);

    /* Light Palette  */
    --theme-light-palette: rgb(238, 241, 244);
    --theme-light-palette-dark: rgb(216, 222, 230);
    --theme-light-palette-darker: rgb(195, 204, 216);
    --theme-light-palette-lighter: rgb(244, 246, 248);
    --theme-light-text: var(--colour-porcelain);
    --theme-light-text-light: rgb(255, 255, 255);
    --theme-light-text-dark: rgb(207, 217, 219);
    --theme-light-text-darker: rgb(163, 182, 187);
    --theme-light-text-active: #fff;

    /* Dark Palette  */
    --theme-dark-palette: var(--color-outer-space);
    --theme-dark-palette-dark: rgb(38, 42, 47);
    --theme-dark-palette-darker: rgb(34, 37, 41);
    --theme-dark-palette-light: rgb(52, 58, 63);
    --theme-dark-palette-lighter: rgb(68, 76, 83);
    --theme-dark-palette-lightest: rgb(77, 85, 93);
    --theme-dark-text: var(--colour-trout);
    --theme-dark-text-light: rgb(128, 135, 150);
    --theme-dark-text-dark: rgb(57, 60, 68);
    --theme-dark-text-active: #6e7584;

    /* Link Colours */
    --link-default: var(--colour-blue-400);
    --link-hover: var(--colour-blue-200);
    --link-active: var(--colour-blue-500);

    /* Forms */
    --form-active: var(--colour-blue-400);

    /* Status Colours */
    --status-default: var(--colour-blue-400);
    --status-negative: var(--colour-red-400);
    --status-positive: var(--colour-mint-400);
    --status-inactive: var(--colour-slate-400);
    --status-neutral: var(--colour-saffron-400);
    --status-sent: var(--colour-mint-400);
    --status-draft: var(--colour-saffron-300);
    --status-approved: var(--colour-violet-400);
    --status-scheduled: var(--colour-teal-400);
    --status-sending: var(--colour-mint-300);
    --status-deployed: var(--colour-mint-400);
    --status-suspended: var(--colour-slate-400);
    --status-opened: var(--colour-blue-400);
    --status-clicked: var(--colour-saffron-300);
    --status-bounced: var(--colour-red-800);
    --status-unsubscribed: var(--colour-saffron-600);
    --status-invalid: var(--colour-red-900);
    --status-converted: var(--colour-red-400);
    --status-entered: var(--colour-blue-400);
    --status-locked: var(--colour-slate-300);
    --status-submitted: var(--stats-approved);

    --status-default-bg: var(--colour-blue-50);
    --status-default-fg: var(--colour-blue-500);
    --status-negative-bg: var(--colour-red-50);
    --status-negative-fg: var(--colour-red-500);
    --status-positive-bg: var(--colour-mint-50);
    --status-positive-fg: var(--colour-mint-400);
    --status-inactive-bg: var(--colour-slate-50);
    --status-inactive-fg: var(--colour-slate-500);
    --status-neutral-bg: var(--colour-saffron-50);
    --status-neutral-fg: var(--colour-saffron-600);

    --status-sent-bg: var(--colour-mint-50);
    --status-sent-fg: var(--colour-mint-600);
    --status-draft-bg: var(--colour-saffron-50);
    --status-draft-fg: var(--colour-saffron-600);
    --status-approved-bg: var(--colour-violet-50);
    --status-approved-fg: var(--colour-violet-500);
    --status-scheduled-bg: var(--colour-teal-50);
    --status-scheduled-fg: var(--colour-teal-600);
    --status-sending-bg: var(--colour-mint-50);
    --status-sending-fg: var(--colour-mint-300);
    --status-deployed-bg: var(--colour-mint-50);
    --status-deployed-fg: var(--colour-mint-600);
    --status-suspended-bg: var(--colour-slate-50);
    --status-suspended-fg: var(--colour-slate-600);
    --status-opened-bg: var(--colour-blue-50);
    --status-opened-fg: var(--colour-blue-400);
    --status-clicked-bg: var(--colour-saffron-50);
    --status-clicked-fg: var(--colour-saffron-300);
    --status-bounced-bg: var(--colour-red-50);
    --status-bounced-fg: var(--colour-red-800);
    --status-unsubscribed-bg: var(--colour-saffron-50);
    --status-unsubscribed-fg: var(--colour-saffron-600);
    --status-invalid-bg: var(--colour-red-50);
    --status-invalid-fg: var(--colour-red-900);
    --status-converted-bg: var(--colour-red-50);
    --status-converted-fg: var(--colour-red-400);
    --status-entered-bg: var(--colour-blue-50);
    --status-entered-fg: var(--colour-blue-400);
    --status-locked-bg: var(--colour-slate-50);
    --status-locked-fg: var(--colour-slate-300);
    --status-submitted-bg: var(--colour-violet-50);
    --status-submitted-fg: var(--colour-violet-500);
    --status-archived-bg: var(--colour-saffron-200);
    --status-archived-fg: var(--colour-saffron-600);

    /* Plot Colours */

    --plot-contactable: var(--colour-saffron-400);
    --plot-contacted: var(--colour-mint-400);
    --plot-engaged: var(--colour-blue-400);
    --plot-converted: var(--colour-red-400);
    --plot-clicked: var(--status-clicked);
    --plot-replied: var(--status-approved);
    --plot-submitted: var(--status-approved);
}
