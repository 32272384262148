/* ==========================================================================
   Layout - Forms
   ========================================================================== */

.field {
    margin: 0 0 20px 0;
    vertical-align: middle;
    position: relative;

    /* Clearfix */
    &::after,
    &::before {
        content: '';
        display: table;
    }
    &::after {
        clear: both;
    }

    &.-nopadding,
    &:last-child:not(.field .field) {
        margin-bottom: 0;
    }

    &.-boxed {
        padding: 10px;
    }

    &.-single {
        margin: 0;
    }
}
