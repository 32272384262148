/* ==========================================================================
   Dropdown Menu
   ========================================================================== */

.dropdown {
    position: relative;

    &.-search {
        position: static;
        height: 40px;
        width: 100%;

        & .dropdown__menu {
            width: 100%;
            max-width: 100%;
            left: 0;

            &.-modal {
                margin-left: -130px;
            }
        }
    }

    &.-rightwards {
        & .dropdown__menu {
            top: 20px;
            left: calc(100% + 10px);
            bottom: auto;
            right: auto;
        }

        & .dropdown__menu::before {
            right: 100%;
            top: 35px;
            margin-top: -20px;
            border-right-color: var(--theme-dark-palette-darker);
            border-bottom-color: transparent;
        }
    }

    &.-bottomright {
        & .dropdown__menu {
            left: -10px;
            bottom: auto;
            right: auto;
        }

        & .dropdown__menu::before {
            left: 29px;
        }
    }
}

.dropdown__toggle {
    display: none;

    &:checked ~ .dropdown__menu {
        opacity: 1;
        display: block;
    }
}

.dropdown__menu {
    list-style: none;
    margin: 0;
    padding: 0;
    min-width: 200px;
    max-width: 300px;
    position: absolute;
    right: -10px;
    top: calc(100% + 8px);
    display: none;
    opacity: 0;
    font-size: 13px;
    text-align: left;
    background-color: var(--colour-slate-100);
    z-index: 300;
    animation: display-none-transition 0.3s;
    border-radius: 8px;

    .dropdown.-responsive & {
        @media (--to-md) {
            position: static;
            top: auto;
        }
    }

    /* &::before {
        border: solid transparent;
        border-color: transparent;
        border-width: 20px;
        content: ' ';
        pointer-events: none;
        height: 0;
        width: 0;
        position: absolute;
        z-index: 300;
        bottom: 100%;
        right: 9px;
        margin-left: -20px;
        border-bottom-color: var(--theme-dark-palette-darker);
    } */
}

.dropdown__link,
.dropdown__action {
    cursor: pointer;
    padding: 10px;
    display: block;
    line-height: 12px;
    font-size: 12px;
    color: var(--text-dark);

    &:not(.dropdown__action):hover {
        background-color: var(--theme-dark-palette);
        color: #fff;
    }

    .dropdown.-responsive & {
        @media (--to-md) {
            padding-left: 30px;
        }
    }
}

.dropdown__action {
    & .dropdown__action--item:not(:last-child) {
        margin-bottom: 10px;
    }
}

.dropdown__title {
    padding: 10px;
    font-weight: 500;
    line-height: 14px;
    color: #fff;
    background-color: #000;

    .dropdown.-responsive & {
        @media (--to-md) {
            padding-left: 20px;
        }
    }
}

@-webkit-keyframes display-none-transition {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
    }
    100% {
        opacity: 1;
    }
}

@keyframes display-none-transition {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
    }
    100% {
        opacity: 1;
    }
}
