/* ==========================================================================
   Base CSS Styles
   ========================================================================== */

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    background-color: #fff;
}

a {
    text-decoration: none;
}
