/* ==========================================================================
   Layout - Global Sections
   ========================================================================== */

.header {
    grid-area: header;
}

.container {
    grid-area: content;
    overflow-y: scroll;
    background-color: var(--colour-slate-50);

    .-settings-open & {
        background-color: #fff;
    }
}

.nav {
    grid-area: nav;
}

.content {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    padding: 24px;
    background: #fff;

    @media (--xl) {
        grid-template-rows: 1fr;
        grid-template-columns: 2fr 1fr;
    }
}

.subscribers-page .content {
    grid-template-rows: 1fr;
}

.stats {
    grid-column-start: 1;
}

.stats-full {
    grid-column-start: 1;
    grid-column-end: 3;
    margin-bottom: 20px;
}

.schedule {
    grid-column-start: 1;
    grid-row-start: 2;

    @media (--xl) {
        grid-column-start: 2;
        grid-row-start: 1;
    }
}