.box {
    margin-bottom: 20px;

    &.-dark {
        background-color: var(--theme-dark-palette-light);

        & .box__header {
            background-color: var(--theme-dark-palette-light);
            border-color: var(--theme-dark-palette-lighter);
            color: var(--theme-light-text);
            display: flex;
        }

        & .box__controls {
            margin-left: auto;
            padding-right: 10px;
            align-self: center;
            font-size: 13px;
        }
    }
}

.box__body {
    &.-padded {
        padding: 20px;
    }

    &.-text {
        font-size: 13px;
    }
}

.box__body::-webkit-scrollbar {
    width: 8px;
}

.box__body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.box__body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: var(--text-dark-light);
}