/* ==========================================================================
   Layout - Grid View
   ========================================================================== */

/*
 * Responsive layout for the activity "cards"
 */

.cardtable__footer {
    display: flex;
    padding: 16px 0 32px 0;
    justify-content: center;
}

.grid {
    container: card / inline-size;
    width: 100%;
    list-style: none;
    padding: 24px;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: 24px;
}

.grid__placeholder {
    @media (--lg) {
        width: 40%;
    }
    @media (--md) {
        width: 60%;
    }

    background-color: #fff;
    border-radius: 3px;
    padding: 0.6em;
    padding-bottom: 2em;
    color: rgba(33, 33, 33, 0.8);
    line-height: 1.3;
    margin: auto;
    margin-top: 1em;
    overflow-wrap: break-word;
    hyphens: auto;

    & ol {
        padding-left: 1.2em;
        padding-right: 0.6em;
    }

    & li {
        margin: 0;
        margin-bottom: 1em;
    }
}
