/* ==========================================================================
   Form Validation
   ========================================================================== */

.field.has-error {
    & .label.-bordered,
    & .label.-bordered + input {
        border-color: var(--status-negative) !important;
    }

    & .icon {
        fill: var(--status-negative) !important;
    }
}

.is-valid {
    & .icon {
        fill: var(--status-positive) !important;
    }
}

.validate {
    padding: 5px 10px 0 1px;
    white-space: nowrap;
    font-size: 12px;

    &.-negative {
        color: var(--status-negative);
    }

    &.-positive {
        display: none;
    }
}

.template__field--error {
    color: var(--status-negative);
    font-size: 0.7rem;
    padding-left: 5px;
    position: relative;
    top: 4px;
}
input:invalid {
    border-color: var(--status-negative) !important;
}
input:invalid + .template__field--error:before {
    content: '⚠️ value entered is not valid. ';
    /*
    padding-left: 5px;
    position: relative;
    top: 4px;
    */
}
