/* ==========================================================================
   Activity Editor
   ========================================================================== */

/* Distribution settings. Similar to a toolbar but is responsive.
   TODO: Possibly create a responsive toolbar module */

.distribution {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (--search-snap) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.distribution__title {
    height: 60px;
    font-size: 13px;
    line-height: 1.5em;
    text-align: left;

    @media (--search-snap) {
        height: auto;
        padding-right: 20px;
    }
}

.distribution__actions {
    white-space: nowrap;

    @media (--search-snap) {
        text-align: right;
    }

    @media (--to-md) {
        & .btn {
            font-size: 11px;
            padding: 10px;
        }
    }
}

/* V5 Activity Page */

.activity {
    background-color: var(--theme-dark-palette);
    position: relative;
    z-index: 10;
    min-height: 340px;
}

.activity__header {
    width: 100%;
    height: 60px;
    margin: 0;
    padding: 0;
}

.activity__body {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-gap: 20px;
    padding: 20px 20px 0;

    @media (--tablet-landscape) {
        grid-template-columns: minmax(280px, 1fr) 3fr;
        grid-template-rows: 1fr;
    }
}

.activity__body > svg {
    grid-column: 1 / span 2;
}

.activity__editor {
    & .channel {
        display: none;
    }

    @media (--tablet-landscape) {
        display: block;
        overflow: auto;

        & .channel {
            display: block;
        }
    }
}

.activity__preview {
    background: white;
    overflow: hidden;
}

/* TODO: The following only applies to the Franchisee pages.
   This will need to be updated once the editor is bought
   over to v5. */

.channel {
    padding: 20px;
    margin-bottom: 10px;
    background: var(--theme-dark-palette-light);

    &.-clickable {
        cursor: pointer;
    }

    &.-unclickable {
        cursor: not-allowed;
    }

    &.-collapsed {
        & .channel__title {
            margin: 0;

            & span {
                display: inline;
                margin: 4px 0 0 0;
                font-weight: normal;
                font-size: 12px;
                float: right;
            }
        }

        & .channel__body {
            display: none;
        }
    }
}

.channel__title {
    margin-top: 0;
    font-size: 16px;
    font-weight: bold;

    & span {
        display: none;
    }

    &::before {
        content: attr(data-before);
    }
}

.channel__hint {
    font-size: 13px;
    line-height: 1.4em;
}
