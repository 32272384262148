/* ==========================================================================
   Status
   ========================================================================== */

.status {
    display: flex;
    justify-items: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 4px;
    outline: none;

    & svg {
        display: block;
        margin: auto;
        width: 24px;
        height: 24px;
    }

    &.-small {
        width: 27px;
        height: 27px;

        & svg {
            height: 14px;
            width: 14px;
        }
    }

    &.-large {
        width: 48px;
        height: 48px;

        & svg {
            width: 24px;
            height: 24px;
        }
    }
}

/* Status Pill
   ========================================================================== */

.status-pill {
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    height: 26px;
    min-width: 28px;
    padding: 0 8px;
    text-transform: capitalize;

    &.-icononly {
        padding: 0;
        width: 26px;
        justify-content: center;
    }

    &.-collapsible {
        width: auto;
        max-width: 30px;
        transition: max-width 0.3s ease-in-out;
        overflow: hidden;

        & .status-pill__text {
            white-space: nowrap;
            position: relative;
            top: 1px;
        }
    }

    .face:hover &.-collapsible {
        max-width: 100%;
    }

    & .status-pill__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 14px;
        width: 14px !important;

        & svg {
            height: 14px;
            width: 14px;
        }
    }

    & .status-pill__text {
        margin-left: 8px;
    }
}

/* Status Loading Animation
   ========================================================================== */

@-webkit-keyframes status-loading {
    from {
        background-position: 0;
    }
    to {
        background-position: 100%;
    }
}

@keyframes status-loading {
    from {
        background-position: 0;
    }
    to {
        background-position: 100%;
    }
}

.status__loading {
    will-change: background-position;
    background-image: repeating-linear-gradient(-45deg, transparent 0 25%, rgba(255, 255, 255, 0.2) 25% 50%);
    background-size: 50% 50%;
    animation: status-loading 1.5s linear infinite;
}

/* Activity Card Status
   ========================================================================== */

.face__status {
    background-color: var(--status-default-bg);
    border-radius: 50%;
    text-align: center;
    color: var(--status-default-fg);

    & svg {
        fill: var(--status-default-fg);
    }
}

[data-status],
[data-status='default'],
[data-status='commented'],
[data-status='analytics'],
[data-status='processing'] {
    background-color: var(--status-default-bg);
    color: var(--status-default-fg);

    & svg {
        fill: var(--status-default-fg);
    }
}

[data-status='created'],
[data-status='draft'],
[data-status='customized-partially'] {
    background-color: var(--status-draft-bg);
    color: var(--status-draft-fg);

    & svg {
        fill: var(--status-draft-fg);
    }
}

[data-status='approved'] {
    background-color: var(--status-approved-bg);
    color: var(--status-approved-fg);

    & svg {
        fill: var(--status-approved-fg);
    }
}

[data-status='scheduled'],
[data-status='queued'],
[data-status='in-progress'] {
    background-color: var(--status-scheduled-bg);
    color: var(--status-scheduled-fg);

    & svg {
        fill: var(--status-scheduled-fg);
    }
}

[data-status='suspended'],
[data-status='cancelled'] {
    background-color: var(--status-suspended-bg);
    color: var(--status-suspended-fg);

    & svg {
        fill: var(--status-suspended-fg);
    }
}

[data-status='broadcasted'],
[data-status='sent'],
[data-status='sending'],
[data-status='finished'],
[data-status='deployed'],
[data-status='customized'],
[data-status='opted-in'],
[data-status='published'],
[data-status='success'] {
    background-color: var(--colour-mint-50);
    color: var(--colour-mint-500);

    & svg {
        fill: var(--colour-mint-500);
    }
}

[data-status='conversion'],
[data-status='error'],
[data-status='bounced'],
[data-status='rejected'],
[data-status='unsubscribed'],
[data-status='not-opted-in'],
[data-status='not-customized'] {
    background-color: var(--status-negative-bg);
    color: var(--status-negative-fg);

    & svg {
        fill: var(--status-negative-fg);
    }
}

[data-status='opened'] {
    background-color: var(--status-opened-bg);
    color: var(--status-opened-fg);

    & svg {
        fill: var(--status-opened-fg);
    }
}

[data-status='clicked'] {
    background-color: var(--status-clicked-bg);
    color: var(--status-clicked-fg);

    & svg {
        fill: var(--status-clicked-fg);
    }
}

[data-status='entered'] {
    background-color: var(--status-entered-bg);
    color: var(--status-entered-fg);

    & svg {
        fill: var(--status-entered-fg);
    }
}

[data-status='locked'] {
    background-color: var(--status-locked-bg);
    color: var(--status-locked-fg);

    & svg {
        fill: var(--status-locked-fg);
    }
}

[data-status='archived'] {
    background-color: var(--status-archived-bg);
    color: var(--status-archived-fg);

    & svg {
        fill: var(--status-archived-fg);
    }
}
