/* ==========================================================================
   Activity Schedule
   ========================================================================== */

/* Modal overrides */
#js-edit-dominosactivity {
    & .modal__body {
        padding: 0;
    }

    & .modal__inner {
        width: 100%;
    }
}

.franchisees-selector {
    background-color: #fff;
    grid-column-start: 1;
    grid-column-end: 3;
}

.franchisees-selector__header {
    border: 0;
}

.franchisees-selector__filter {
    padding: 16px 0;

    & .text {
        border-radius: 20px;
        width: 100%;
        padding-left: 10px;
    }
}

.franchisees-selector__list {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    max-height: calc(100vh - 190px);

    & li {
        &:nth-child(even) {
            background-color: var(--colour-slate-50);
        }

        &:hover {
            background-color: var(--colour-slate-100);
        }

        & a {
            color: var(--theme-dark-text);
            padding: 8px 16px;
            display: block;
            font-size: 13px;
        }
    }
}

.franchisees-selector__id {
    font-size: 11px;
    color: var(--theme-dark-text-light);
}

/* To be moved into custom stylesheet for Domino's */

/* Domino's Dashboard Layout */

.storeselect {
    position: relative;
    width: 100%;
}

.storeselect__toggle {
    display: none;

    &:checked ~ .storeselect__list {
        opacity: 1;
        display: block;
    }
}

.storeselect__button {
    display: block;
    height: 60px;
    width: calc(100vw - 60px);
    padding-left: 20px;
    padding-right: 20px;
    background: var(--theme-dark-palette-light);
    line-height: 60px;
    color: #fff;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;

    &:hover {
        color: #fff;
    }

    &::before {
        display: inline-block;
        position: absolute;
        z-index: 100;
        top: 0;
        right: 0;
        height: 60px;
        width: 60px;
        content: '';
        background: var(--theme-dark-palette-lighter);
    }

    &::after {
        display: inline-block;
        position: absolute;
        z-index: 101;
        top: 50%;
        right: 23px;
        content: '';
        width: 0;
        height: 0;
        margin-top: -3px;
        pointer-events: none;
        border-top: 6px solid var(--theme-light-text-light);
        border-right: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid transparent;
        border-radius: 0;
    }
}

.storeselect__list {
    display: none;
    opacity: 0;
    position: absolute;
    z-index: 2;
    top: 60px;
    background: var(--theme-dark-palette-darker);
    width: 100%;
}

.storeselect__item {
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;

    &:hover {
        & .storeselect__name,
        & .storeselect__checkbox {
            background: var(--theme-dark-palette-lighter);
            color: #fff;
        }
    }
}

.storeselect__status {
    width: 60px;
    height: 60px;
    padding-top: 13px;
    text-align: center;
}

.storeselect__name {
    display: block;
    width: calc(100% - 120px);
    height: 60px;
    padding-left: 20px;
    line-height: 60px;
    color: #fff;
}

.storeselect__checkbox {
    background: var(--theme-dark-palette-dark);
    width: 60px;
    height: 60px;
    text-align: center;
    padding: 8px 0 0 8px;
}

.storeselect__locked {
    margin-left: 8px;
    position: relative;
    top: 3px;
}

.drop__placeholder {
    height: 100px;
    border: 1px dashed rgb(91, 101, 111);
    background: var(--theme-dark-palette-lighter) !important;
}

/* Coupons */
.coupons {
    height: 420px;
    overflow-y: scroll;
    margin-bottom: 20px;
}

.coupon {
    font-size: 13px;
    margin-bottom: 10px;
    background: var(--theme-dark-palette);

    &:last-child {
        margin-bottom: 0;
    }

    & img {
        width: 70px;
    }

    & h3 {
        margin: 0 0 0.5em;
        font-size: 13px;
        font-weight: bold;
    }

    & p {
        margin: 0.5em 0;
        line-height: 1.4;

        &:last-child {
            margin-bottom: 0;
        }
    }

    & .small {
        font-size: 10px;
    }
}

ui-edit-dominosactivity {
    & .pageheader__actions .pageheader__id::after {
        content: '';
    }
}

/** Preview **/
/* ==========================================================================
   Activity Preview
   ========================================================================== */

.preview {
    width: 100%;
}

.preview__controls {
    background-color: var(--theme-dark-palette-light);
    min-height: 50px;
    padding: 9px 10px;
    margin-bottom: 0 !important;
    font-size: 12px;

    & .split__left {
        white-space: nowrap;

        & .form__select {
            max-width: 120px;
            margin-right: 8px;
        }
    }
}

.preview__envelope {
    margin: 0;
    padding: 10px 10px 5px;
    color: #fff;
    background: #3b4148;
    font-size: 13px;

    & dl {
        margin: 0;
        padding: 0 0 5px 0;
    }

    & dt {
        width: 60px;
        margin: 0;
        padding-right: 5px;
        text-align: right;
        display: inline-block;
        color: var(--theme-light-text-dark);
    }

    & dd {
        margin: 0;
        padding: 0;
        display: inline;
    }
}

.preview__subject {
    font-weight: bold;
}

.preview__frame {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
    border: none;
    padding: 0;
    background: transparent;
    position: relative;
    z-index: 1;

    &.-mobile {
        width: 320px;
    }
}

.preview__wrap {
    margin-bottom: 0;
}

.preview__raw,
.preview__debug {
    color: #000;
    width: 100%;
    border: none;
    padding: 10px;
    margin: 0 auto;
    background: white;
    font-family: monospace;
    white-space: pre-wrap;
    word-wrap: break-word;
}

ui-dominos-activity-preview .preview__envelope {
    height: 95px;
}

ui-dominos-activity-preview .preview__wrap {
    max-height: calc(100vh - 215px);
    margin-bottom: 0;
    overflow-y: scroll;

    @media (--tablet-landscape) {
        max-height: calc(100vh - 235px);
    }
}

.sms_wrap {
    display: flex;
    justify-content: center;
}

.sms__screen {
    min-height: 600px;
    width: 600px;
    border-width: 0 5px;
    border-color: #ccc;
    border-style: solid;
}

.sms__header {
    padding: 20px 0;
    background-color: #e5e5ea;
    font: bold 28px/1.4em 'helvetica neue', helvetica, arial, sans-serif;
    width: 100%;
    color: #000;
    text-align: center;
    box-sizing: border-box;
    border-bottom: 1px solid #ddd;
}

.sms__body {
    box-sizing: border-box;
    width: 600px;
    padding: 40px;
}

.sms__msg {
    width: 60%;
    position: relative;
    padding: 15px 30px;
    color: #fff;
    background: #0b93f6;
    border-radius: 25px;
    font: 26px/1.4em 'helvetica neue', helvetica, arial, sans-serif;
}

.sms__msg:before {
    content: '';
    position: absolute;
    z-index: 2;
    bottom: -2px;
    left: -7px;
    height: 25px;
    border-left: 20px solid #0b93f6;
    border-bottom-right-radius: 16px 14px;
    transform: translate(0, -2px);
}

.sms__msg:after {
    content: '';
    position: absolute;
    z-index: 3;
    bottom: -2px;
    left: 4px;
    width: 26px;
    height: 25px;
    background: #fff;
    border-bottom-right-radius: 10px;
    transform: translate(-30px, -2px);
}

/* ==========================================================================
   Icons
   ========================================================================== */
.icon {
    width: 18px;
    height: 18px;
    transition: all 0.3s ease-out;
    fill: var(--theme-dark-text);
    pointer-events: none;
    /* Sizes
   ========================================================================== */
    &.-xxs,
    &.-xx-small {
        width: 11px;
        height: 11px;
    }
    &.-xs,
    &.-x-small {
        width: 12px;
        height: 12px;
    }
    &.-s,
    &.-small {
        width: 14px;
        height: 14px;
    }
    &.-m,
    &.-medium {
        width: 16px;
        height: 16px;
    }
    &.-l,
    &.-large {
        width: 21px;
        height: 21px;
    }
    &.-xl,
    &.-x-large {
        width: 24px;
        height: 24px;
    }
    &.-xxl,
    &.-xx-large {
        width: 32px;
        height: 32px;
    }
    /* Colours
   ========================================================================== */
    &.-dimmed {
        color: var(--theme-dark-text-light);
    }
    &.-black {
        fill: #000;
    }
    &.-white {
        fill: #fff;
    }
    &.-light {
        fill: var(--theme-light-text);
    }
    &.-dark {
        fill: var(--theme-dark-text);
    }
    &.-negative {
        fill: var(--status-negative) !important;
    }
    &.-positive {
        fill: var(--status-positive) !important;
    }
}
