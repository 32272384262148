/* ==========================================================================
   Media
   ========================================================================== */

.media {
    display: flex;
    align-items: center;
}

.media__img {
    margin-right: 1em;
}

.media__body {
    flex: 1;
}
