/* ==========================================================================
   Page Loader
   ========================================================================== */

.loader,
.loader::before,
.loader::after {
    background: var(--form-active);
    width: 0.3em;
    height: 4em;
    animation: loading 1s infinite ease-in-out;
}

.loader::before,
.loader::after {
    position: absolute;
    top: 0;
    content: '';
}

.loader::before {
    left: -1.8em;
    animation-delay: -0.32s;
}

.loader::after {
    left: 1.8em;
}

.loader {
    display: inline-block;
    text-indent: -9999em;
    position: relative;
    transform: translateZ(0);
    animation-delay: -0.16s;
    font-size: 10px; /* Force pxtorem to ignore */

    &.-small {
        font-size: 5px; /* Force pxtorem to ignore */
    }

    &.-tiny {
        font-size: 3px; /* Force pxtorem to ignore */
    }
}

@-webkit-keyframes loading {
    0%,
    80%,
    100% {
        box-shadow: 0 0 var(--form-active);
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em var(--form-active);
        height: 5em;
    }
}

@keyframes loading {
    0%,
    80%,
    100% {
        box-shadow: 0 0 var(--form-active);
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em var(--form-active);
        height: 5em;
    }
}
