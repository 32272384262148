/* ==========================================================================
   Login
   ========================================================================== */

.login__page {
    /* Used for modal-style layout for the password reset and account creation pages */
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    background: white;
    padding: 20px;
}

.login {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;

    @media (--sm) {
        width: 320px;
    }

    & form {
        margin: 0;
    }

    & .alert {
        margin: 0 0 20px;
    }

    & .btn {
        display: inline-block;
        float: left;
    }
}

.login__logo {
    width: 200px;
    margin: 0 auto 40px;
}

.login__submit {
    margin-top: 16px;

    /* Clearfix */
    &::after,
    &::before {
        content: '';
        display: table;
    }
    &::after {
        clear: both;
    }
}

.login__actions {
    display: inline-block;
    padding-top: 2px;
    float: right;
    font-size: 12px;
    line-height: 1.5em;
    text-align: right;
}

.login__footer {
    margin-top: 20px;
    padding-top: 10px;
    font-size: 10px;
    color: #aaa;
    text-align: center;

    & > a {
        display: inline-block;
        padding: 3px;
        color: #aaa;
    }
}

/* New Password Strength
   ========================================================================== */

.newpassword {
    position: relative;

    & input {
        position: relative;
        z-index: 10;
        background: transparent;
    }
}

.newpassword__result {
    position: absolute;
    right: 10px;
    top: 11px;
    font-size: 11px;
    z-index: 10;
}

.newpassword__score {
    position: absolute;
    height: 32px;
    z-index: 1;
    transition: width 250ms, background-color 250ms;
    border-radius: 4px;
}

[data-password-score='0'] {
    background-color: #fff;
    width: 0;
}

[data-password-score='1'] {
    background-color: #ffa0a0;
    width: 25%;
}

[data-password-score='2'] {
    background-color: #ffec8b;
    width: 50%;
}

[data-password-score='3'] {
    background-color: #c3ff88;
    width: 75%;
}

[data-password-score='4'] {
    background-color: #8ff22f;
    width: 100%;
}
