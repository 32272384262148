/* ==========================================================================
   Layout - List Views
   ========================================================================== */

.cardtable.-listview {
    background: #fff;

    & .cardtable__body {
        padding: 0 24px;
    }
}

.list-view__wrapper {
    overflow-x: auto;
}

.list-view {
    & thead th,
    & tfoot td {
        height: 52px;
        padding: 8px;
        font-weight: 600;
        text-align: left;
    }

    & td {
        padding: 8px;
    }

    & tr th:first-child,
    & tr td:first-child {
        padding-left: 16px;
    }

    & tr th:last-child,
    & tr td:last-child {
        padding-right: 16px;
    }

    .-locked {
        pointer-events: none;

        & td,
        & td a {
            color: var(--colour-slate-200);
        }

        .status {
            /* opacity: 33%; */
        }
    }
}

.list-view__sortable {
    transition: background-color 0.3s ease-out;
    &:active {
        background-color: var(--theme-light-palette-dark);
    }

    &--active {
        background-color: var(--theme-light-palette-dark);

        & .icon {
            fill: var(--text-dark);
        }
    }

    .list-view.-dark & {
        &:hover,
        &:active {
            background-color: var(--theme-dark-palette-darker);
        }
    }

    & .icon {
        display: inline-block;
        vertical-align: middle;
        fill: var(--text-dark);
    }

    & b {
        display: inline-block;
        position: relative;
        top: 1px;
    }
}

.list-view__header,
.list-view__footer {
    color: var(--text-dark);

    .list-view.-dark & {
        color: var(--theme-light-text);
    }
}

.list-view__row {
    color: var(--text-dark);
    font-weight: 200;

    & td {
        line-height: 1.4;
        white-space: nowrap;
        border-top: 1px solid var(--border-light);
        font-weight: 400;
    }

    &:last-child td {
        border-bottom: 1px solid var(--border-light);
    }

    & a:not(.dropdown__link) {
        color: var(--text-dark);

        &:hover {
            color: var(--text-dark-light);
        }

        .list-view.-dark & {
            color: var(--theme-light-text);
        }
    }

    .list-view.-dark & {
        color: var(--theme-light-text);

        & td {
            border-color: var(--border-dark);
        }

        &:last-child td {
            border-color: var(--border-dark);
        }
    }

    &--action {
        background: var(--theme-light-palette-lighter);
        height: 40px;
    }

    .form__checkbox {
        width: 16px;
        padding-left: 0;
        padding-right: 0;
    }
}

.list-view__row-inactive {
    color: var(--theme-light-text);

    .list-view.-dark & {
        color: var(--text-dark-light);
    }
}

/*  Make dropdown links contextual by hiding them.
    TODO: Improve implementation */

.list-view__row[resource='Campaign'] {
    & .resource__content,
    & .resource__subscribers,
    & .-resource-edit,
    & .-resource-expression,
    & .-resource-clone {
        display: none;
    }
}

.list-view__row[resource='List'] {
    & .-resource-edit,
    & .-resource-expression,
    & .-resource-clone,
    & .resource__content {
        display: none;
    }
}

.list-view__row[resource='Activity'] {
    & .resource__subscribers {
        display: none;
    }
}

.list-view__action {
    width: 40px;
    padding: 0 !important;
    text-align: right;
}

.list-view__header-action {
    padding: 10px;
    text-align: left;

    & .btn--icon {
        color: var(--text-dark);
    }

    .list-view.-dark & {
        background-color: var(--theme-dark-palette-dark);
        color: var(--theme-light-text);
    }

    & .list--inline > li {
        float: none;
    }
}

.list-view__url {
    display: table-row;
}

.list-view__title,
.list-view__icon {
    display: table-cell;
}

.list-view__title {
    padding-left: 10px;
}

.list-view__stats {
    display: grid;
    width: 100%;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 24px;
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
        flex-grow: 1;
    }

    & .list-view__stats-title {
        margin: 0;
        color: var(--colour-slate-300);
        text-transform: lowercase;
    }

    & p {
        margin: 0;
    }

    & .statsbar {
        width: 95%;
    }
}

.list-view__row:hover {
    & td {
        background-color: var(--colour-slate-50);

        .-dark & {
            background-color: var(--colour-slate-800);
        }
    }

    .list-view__stats-title {
        &.-contactable {
            color: var(--plot-contactable);
        }

        &.-contacted {
            color: var(--plot-contacted);
        }

        &.-engaged {
            color: var(--plot-engaged);
        }

        &.-converted {
            color: var(--plot-converted);
        }

        &.-submitted {
            color: var(--plot-submitted);
        }

        &.-replied {
            color: var(--plot-replied);
        }

        &.-value,
        &.-avg-value,
        &.-avg-value-send,
        &.-removed,
        &.-predicted-total-value,
        &.-predicted-avg-value {
            color: var(--status-converted);
        }

        &.-added,
        &.-sent,
        &.-viewed,
        &.-total {
            color: var(--status-sent);
        }

        &.-opened,
        &.-total-opened {
            color: var(--status-opened);
        }

        &.-clicked,
        &.-total-clicked {
            color: var(--status-clicked);
        }

        &.-bounced,
        &.-bounce-rate {
            color: var(--status-bounced);
        }

        &.-invalid {
            color: var(--status-invalid);
        }
    }
}

.list-view__stat {
    text-align: right;
}

.list-view__preview,
.list-view__preview-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    background-size: cover;
    border-radius: 4px;
}

.list-view__preview-img {
    border: 1px solid var(--colour-slate-100);
}

.list-view__preview {
    background-color: var(--colour-blue-50);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 20px;

    &.-asset {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='%230086ff' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='2' y='2' width='20' height='20' fill='none' stroke='%230086ff' stroke-width='2'%3E%3C/rect%3E%3Cpolyline points='6 16 8 14 13 18 18 13' fill='none' stroke='%230086ff' stroke-width='2'%3E%3C/polyline%3E%3Ccircle cx='11' cy='8' r='2' fill='none' stroke='%230086ff' stroke-width='2'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
    }

    &.-displayad {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 24 24'%3E%3Cpath d='m24 21.8-5-20c-.1-.5-.5-.8-1-.8H4.7c-.1 0-.2.1-.3.1h-.1c0 .2-.1.3-.1.4v.1c-.1 0-.2.1-.2.2l-4 17c-.1.5.2 1.1.7 1.2s1.1-.2 1.2-.7L5 6.2l4 16c.1.5.5.8 1 .8h.2c.5-.1.9-.7.7-1.2L9.8 17h10.9l1.3 5.2c.1.5.5.8 1 .8h.2c.6-.2.9-.7.8-1.2M9.3 15l-3-12h10.9l3 12z'/%3E%3C/svg%3E");
    }

    &.-email {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='%230086ff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1,13v6a2,2,0,0,0,2,2H21a2,2,0,0,0,2-2V13' fill='none' stroke='%230086ff' stroke-width='2'%3E%3C/path%3E %3Cpath d='M23,8V5a2,2,0,0,0-2-2H3A2,2,0,0,0,1,5V8l11,6Z' fill='none' stroke='%230086ff' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }

    &.-email-triggered {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 24 24'%3E%3Cpath fill='%230086ff' d='M23 12c-.6 0-1 .4-1 1v6c0 .6-.4 1-1 1H3c-.6 0-1-.4-1-1v-6c0-.6-.4-1-1-1s-1 .4-1 1v6c0 1.7 1.3 3 3 3h18c1.7 0 3-1.3 3-3v-6c0-.6-.4-1-1-1'/%3E%3Cpath fill='%230086ff' d='M21 2H3C1.3 2 0 3.3 0 5v3c0 .4.2.7.5.9l8 4.4L9.8 12l-.7-.7L2 7.4V5c0-.6.4-1 1-1h18c.6 0 1 .4 1 1v2.4l-5.5 3c.3.5.5 1 .5 1.6v.5l6.5-3.6c.3-.2.5-.5.5-.9V5c0-1.7-1.3-3-3-3'/%3E%3Cpath fill='%230086ff' d='M10 17c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l3.3-3.3-3.3-3.3c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l4 4c.4.4.4 1 0 1.4l-4 4c-.2.2-.4.3-.7.3' /%3E%3Cpath fill='%230086ff' d='M10 17c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l3.3-3.3-3.3-3.3c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l4 4c.4.4.4 1 0 1.4l-4 4c-.2.2-.4.3-.7.3'/%3E%3C/svg%3E");
    }

    &.-line {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 24 24'%3E%3Cpath d='M2.6 17.2 2 21.9c0 .4.1.7.4.9.2.1.4.2.6.2.1 0 .3 0 .4-.1L9 20.7q1.35.3 3 .3c6.6 0 12-4.5 12-10S18.6 1 12 1 0 5.5 0 11c0 2.2.9 4.3 2.6 6.2M2 11c0-4.4 4.5-8 10-8s10 3.6 10 8-4.5 8-10 8c-1.1 0-2-.1-2.8-.4h-.3c-.1 0-.3 0-.4.1l-4.3 1.8.4-3.5c0-.3-.1-.6-.3-.8C2.8 14.6 2 12.8 2 11'/%3E%3C/svg%3E");
    }

    &.-line-triggered {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 24 24'%3E%3Cpath d='M2.6 17.2 2 21.9c0 .4.1.7.4.9.2.1.4.2.6.2.1 0 .3 0 .4-.1L9 20.7q1.35.3 3 .3c6.6 0 12-4.5 12-10S18.6 1 12 1 0 5.5 0 11c0 2.2.9 4.3 2.6 6.2M2 11c0-4.4 4.5-8 10-8s10 3.6 10 8-4.5 8-10 8c-1.1 0-2-.1-2.8-.4h-.3c-.1 0-.3 0-.4.1l-4.3 1.8.4-3.5c0-.3-.1-.6-.3-.8C2.8 14.6 2 12.8 2 11'/%3E%3Cpath d='M10.6 16c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l3.3-3.3-3.3-3.3c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l4 4c.4.4.4 1 0 1.4l-4 4c-.2.2-.4.3-.7.3'/%3E%3C/svg%3E");
    }

    &.-sms {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='%230086ff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='4' y1='18' x2='20' y2='18' fill='none' stroke='%230086ff' stroke-width='2'%3E%3C/line%3E%3Cpath d='M18,23H6a2,2,0,0,1-2-2V3A2,2,0,0,1,6,1H18a2,2,0,0,1,2,2V21A2,2,0,0,1,18,23Z' fill='none' stroke='%230086ff' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }

    &.-sms-triggered {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 24 24'%3E%3Cpath d='M18 0H6C4.3 0 3 1.3 3 3v18c0 1.7 1.3 3 3 3h12c1.7 0 3-1.3 3-3V3c0-1.7-1.3-3-3-3M6 2h12c.6 0 1 .4 1 1v14H5V3c0-.6.4-1 1-1m12 20H6c-.6 0-1-.4-1-1v-2h14v2c0 .6-.4 1-1 1'/%3E%3Cpath d='M10 14.5c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l3.3-3.3-3.3-3.3c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l4 4c.4.4.4 1 0 1.4l-4 4c-.2.2-.4.3-.7.3'/%3E%3C/svg%3E");
    }

    &.-web {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='%230086ff' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='1' y='2' fill='none' stroke='%230086ff' stroke-width='2' width='22' height='20'%3E%3C/rect%3E %3Cline fill='none' stroke='%230086ff' stroke-width='2' x1='1' y1='6' x2='23' y2='6'%3E%3C/line%3E %3Crect x='5' y='11' fill='none' stroke='%230086ff' stroke-width='2' width='6' height='6'%3E%3C/rect%3E %3Cline fill='none' stroke='%230086ff' stroke-width='2' x1='15' y1='11' x2='19' y2='11'%3E%3C/line%3E %3Cline fill='none' stroke='%230086ff' stroke-width='2' x1='15' y1='15' x2='17' y2='15'%3E%3C/line%3E %3Cline fill='none' stroke='%230086ff' stroke-width='2' x1='6' y1='2' x2='6' y2='6'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
    }
}

.list-view__preview-popup {
    min-height: 300px;
    width: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    &.-is-asset {
        width: 300px;
    }
}

.list-view td.clipped {
    max-width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
