/* ==========================================================================
   Alerts
   ========================================================================== */

/*
 * <div class="alert">
 *   <div class="alert__actions"><button></button></div>
 *   <div class="alert__body">Alert message</div>
 * </div>
 */

.alert {
    margin-bottom: 8px;
    padding: 8px;
    background: var(--status-default);
    font-size: 13px;
    line-height: 1.4em;
    color: #fff;

    &.-neutral {
        background: #fff8db;
        color: #4f4415;
    }

    &.-positive {
        background-color: var(--status-positive);
    }

    &.-negative {
        background-color: var(--status-negative);
    }

    &.-inactive {
        background-color: var(--status-inactive);
    }

    &.-transparent {
        background-color: transparent;
        color: var(--text-dark);
    }

    & a {
        color: #fff;
        text-decoration: underline;
    }

    &.-rounded {
        border-radius: 4px;
    }

    & .btn-link:last-child {
        text-decoration: none;
        margin-left: 8px;
        position: relative;
        top: 2px;
    }
}

.alert__actions {
    margin-right: 20px;
}

/* Alert - Global Pop-up
   ========================================================================== */

.alert__global {
    position: fixed;
    left: 0;
    top: 0;
    margin-left: 0;
    width: 100%;
    z-index: 9999;

    & .alert__global--list {
        margin: 0;
        list-style-type: none;
        padding: 0 6px;
    }

    @media (--search-snap) {
        top: 2em;
        left: 50%;
        margin-left: -150px;
        width: auto;
    }
}

/* Alert Fixed
   ========================================================================== */

.alert__fixed {
    position: fixed;
    margin-bottom: 0;
    bottom: 0;
    width: 100%;
    z-index: 9000;
    display: flex;
    align-items: center;
}

/* Vue slide transition */
.alert-slide-enter-active,
.alert-slide-leave-active {
    transition: all 0.3s ease;
}

.alert-slide-enter,
.alert-slide-leave-to {
    transform: translateY(60px);
    opacity: 0;
}
