/* ==========================================================================
   Toolbars
   ========================================================================== */

.toolbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 41px;
    border-bottom: 1px solid var(--body-background-color);
    background-color: #fff;
    color: var(--text-dark);

    &.-padded {
        padding: 20px 0;
    }

    &.-pagedivider {
        height: 60px;
        padding-top: 10px;
        border: 0;
    }

    &.-dark {
        background: var(--theme-dark-palette-darker);
        border-color: var(--theme-dark-palette-light);
    }

    &.-extended {
        height: 78px;
        justify-content: flex-start;
    }

    &.-flex-topdown {
        flex-direction: column;
    }
}

.toolbar__title {
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 16px;
    line-height: 41px;
    color: var(--text-dark);

    &.-indented {
        padding-left: 12px;
    }

    .toolbar.-pagedivider & {
        padding-left: 20px;
        line-height: 1;
    }

    .toolbar.-dark & {
        color: var(--theme-light-text);
    }
}

.toolbar__actions {
    text-align: right;

    .toolbar.-pagedivider & {
        padding-right: 20px;
    }
}

.toolbar__link {
    font-size: 13px;
    padding-right: 10px;
}

.toolbar__menu {
    margin: 4px;
}

.toolbar__loader {
    position: relative;

    & .loader {
        transition: visibility 0s, opacity 0.3s ease-in-out;
        position: absolute;
        right: 20px;
        top: 15px;
    }
}

.toolbar__success {
    transition: visibility 0s, opacity 0.3s ease-in-out;
    position: absolute;
    right: 12px;
    top: 12px;
}
