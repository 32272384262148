/* ==========================================================================
   Tables
   ========================================================================== */

table {
    width: 100%;
    font-size: 12px;
    border-collapse: collapse;
}

th {
    padding: 16px;
    font-weight: 800;
    white-space: nowrap;
}

td {
    padding: 8px 16px;
}

.table {
    table-layout: fixed;

    & tr {
        border-bottom: 1px solid var(--theme-dark-palette-lighter);

        &:last-child {
            border: 0;
        }
    }

    & td {
        overflow-wrap: break-word;
        line-height: 1.4em;
    }

    &.-data {
        & tr td:first-child {
            font-weight: bold;
        }
    }
}

.table__cell {
    &.-third {
        width: 33.33333%;
    }
}
